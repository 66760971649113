import ReactGA from 'react-ga';

const ErrorLogger = {
  log: (source, err) => {
    console.error(err);

    ReactGA.exception({ description: `Env: ${process.env.NODE_ENV} :: From: ${source} :: ${err.toString()}` })
  },
};

export default ErrorLogger;
