import { Component } from 'react';
import { Typography } from '@material-ui/core';
import ErrorLogger from 'utils/errorLogger';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    const stackString = errorInfo.componentStack.split('\n').filter((a) => !!a);
    const errorComponent = stackString[0].trim();
    ErrorLogger.log(errorComponent, error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='formSkeleton'>
          <div className='formCover2'></div>
          <div className='formBody'>
            <div className='formHeader'>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Typography style={{ fontSize: '1.5rem', fontWeight: 600, textAlign: 'center' }}>
                  Something went wrong.
                </Typography>
                <Typography style={{ fontSize: '1.5rem', fontWeight: 600, textAlign: 'center' }}>
                  Please contact the admin for details.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
