import MainRouter from './pages/MainRouter';
import {
  CssBaseline,
  ThemeProvider,
} from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ErrorBoundary from 'pages/ErrorBoundary';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1d6ed8',
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ErrorBoundary>
          <CssBaseline />
          <MainRouter />
        </ErrorBoundary>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
